import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";
import { PAGE_URL } from "../../../constants";
import { ContactDenorm, ContactOrgDenorm } from "../../../store";
import { crm } from "../../../store/cabinetApi/crm";
import { selectDenormalizedData } from "../../../store/crm/selectors";
import { transformMuiDataGridFilterSortToApiModel } from "../../../utils/dataUtils";
import { AppCrmContactsListApiArg } from "../../../store/cabinetApi/generated/crm";
import { useDeepCompareEffect } from "react-use";


type ContactsApiFilter = Omit<AppCrmContactsListApiArg, 'offset'|'limit'>;

const useContactsList = ({ pageSize, defaultFilter, defaultSort, search }: {
  pageSize: number; defaultFilter?: GridFilterModel; defaultSort?: GridSortModel; search?: string
}) => {
  const [contactPage, setContactPage] = useState(0);
  const [contactsFilter, setContactsFilter] = useState<GridFilterModel>(defaultFilter || { items: [] });
  const [contactsSort, setContactsSort] = useState<GridSortModel>(
    defaultSort || [{ field: 'created_at', sort: 'desc' }]
  );

  const contactsApiFilter: ContactsApiFilter = useMemo(() => {
    return transformMuiDataGridFilterSortToApiModel({ filterModel: contactsFilter, sortModel: contactsSort }, true);
  }, [contactsFilter, contactsSort]);

  const { data: contactData, isFetching: loadingContacts } = crm.useAppCrmContactsListQuery({
    limit: pageSize, offset: contactPage * pageSize, search, hiddenDateIsnull: true,
    ...contactsApiFilter,
  });

  const { data: orgData, isFetching: loadingOrgs } = crm.useAppCrmCompaniesListQuery({
    limit: 1000, hiddenDateIsnull: true
  });
  // TODO: remove limit from this request. Only adding it to get the right response shape for now
  const { data: contactCategories } = crm.useAppCrmContactCategoriesListQuery({ limit: 1000 });

  const contacts = contactData && selectDenormalizedData(contactData) as ContactDenorm[];
  const orgs = orgData && selectDenormalizedData(orgData) as ContactOrgDenorm[];

  const [updateContact] = crm.useAppCrmContactsPartialUpdateMutation();

  const navigate = useNavigate();

  const handleNavigateToContact = (contactId: ContactDenorm['id']) => (
    navigate(`${PAGE_URL.CRM_PEOPLE}/${contactId}`)
  );

  const handleSetContactsFilter = (filter: GridFilterModel | undefined) => {
    setContactPage(0);
    setContactsFilter(filter || { items: [] });
  };

  const handleSetContactsSort = (sort: GridSortModel | undefined) => {
    setContactPage(0);
    setContactsSort(sort || []);
  };

  const handleUpdateContact = useCallback(async (
    contact: Partial<Omit<ContactDenorm, 'company'>> & { id: ContactDenorm['id'] }
  ) => {
    const res = await updateContact({ id: contact.id, patchedContact: {
      contact_category: contact.contact_category,
      hidden_date: contact.hidden_date,
    } });
    if ('data' in res) {
      return selectDenormalizedData(res.data) as ContactDenorm;
    }
  }, [updateContact]);

  useDeepCompareEffect(() => setContactsFilter(defaultFilter || { items: [] }), [defaultFilter]);
  // reset filter anytime search changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setContactsFilter(defaultFilter || { items: [] }), [search]);

  return {
    contacts: contacts || [],
    rowCount: contactData?.meta?.count || 0,
    loading: loadingContacts || loadingOrgs,
    filter: contactsFilter,
    sort: contactsSort,
    orgOptions: orgs || [],
    categories: contactCategories?.data || [],
    page: contactPage,
    pageSize,
    onNavigateToContact: handleNavigateToContact,
    onSetFilter: handleSetContactsFilter,
    onSetSort: handleSetContactsSort,
    onPageChange: setContactPage,
    onUpdateContact: handleUpdateContact,
  };
};

export default useContactsList;
